import React from 'react';
import { basePaths } from 'app-constants';
import { useAssetAccess } from 'components/asset-access';
import { AppContext } from 'components/app-context';
import { matchPath, useLocation } from 'react-router-dom';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import styled, { css } from 'styled-components';
import { useSearchQueryNavigation } from 'components/layouts/research-layout/useSearchQueryNavigation';
import { getPartialValue } from 'components/layouts/research-layout/getPartialValue';
import { matchPaths } from 'components/content-renderer/getContentRoute';
import { JsonForm } from '../common/json_form';
import useQueryString from 'hooks/useQueryString';
import { debounce } from 'lodash/fp';
import { HeaderSearchInput } from './HeaderSearchInput';
import {
  HeaderMenu,
  HeaderMenuListItem,
  StyledHeaderMenuList,
} from './HeaderMenu';
import OnLawIcon from '../common/icons/OnLaw';
import PrimaryLaw from '../common/icons/PrimaryLaw';
import { useSearchTitleForPathname } from 'hooks/useSearchTitleForPathname';

function getCurrentFromPath(pathname: string, _search?: string) {
  if (
    matchPath('/', pathname) ||
    matchPath('/news/*', pathname) ||
    matchPath('/posts/*', pathname)
  )
    return 'DailyNews';

  if (matchPath('/primary-law/*', pathname))
    return getPrimaryLawTypeFromPath(pathname);
  if (matchPath('/secondary-sources/*', pathname)) return 'Secondary Sources';
  if (matchPath('/practitioner/*', pathname)) return 'Practitioner';
  if (matchPath('/learning/*', pathname)) return 'Learning';
  if (matchPath('/onlaw/*', pathname)) return 'OnLAW';
  if (matchPath('/matters/*', pathname)) return 'Client Matters';
  if (matchPath('/documents_and_forms/*', pathname)) return 'Documents & Forms';
  return 'Choose a Section';
}

function getPrimaryLawTypeFromPath(pathname: string) {
  if (matchPath('/primary-law/cases/*', pathname)) return 'Cases';
  if (matchPath('/primary-law/statutes/*', pathname)) return 'Statutes';
  if (matchPath('/primary-law/rules/*', pathname)) return 'Rules of Court';

  return 'Primary Law';
}

export const LoggedInNav = () => {
  const {
    hasPractitionerVisible,
    hasOnlawProducts,
    hasCommunityProduct,
    hasFormsAndMatters,
  } = useAssetAccess();

  const { localUser } = React.useContext(AppContext);
  const location = useLocation();
  const { pathname, search } = location;
  const { query: defaultQuery } = useQueryString();

  const [query, setQuery] = React.useState(defaultQuery);

  const {
    isDailyNewsSubsection,
    isCaseSection,
    isStatutesSection,
    isRulesSection,
    isSecondarySourcesSection,
    isPractitionerSection,
    isOnLawSection,
    isMattersSection,
    isDocsAndFormsSection,
    defaultSearchType,
    current,
    showSearch,
  } = React.useMemo(() => {
    return {
      isDailyNewsSubsection:
        matchPaths(['/', '/news/*', '/posts/*'], pathname)?.length > 0,
      isCaseSection: !!matchPath('/primary-law/cases/*', pathname),
      isStatutesSection: !!matchPath('/primary-law/statutes/*', pathname),
      isRulesSection: !!matchPath('/primary-law/rules/*', pathname),
      isSecondarySourcesSection: !!matchPath('/secondary-sources/*', pathname),
      isPractitionerSection: !!matchPath('/practitioner/*', pathname),
      isOnLawSection: !!matchPath('/onlaw/*', pathname),
      isMattersSection: !!matchPath('/matters/*', pathname),
      isDocsAndFormsSection: !!matchPath('/documents_and_forms/*', pathname),
      // default to DailyNews
      defaultSearchType:
        getPartialValue(Object.values(basePaths))(pathname) || basePaths.news,
      current: getCurrentFromPath(pathname, search),
      showSearch:
        matchPaths(
          [
            '/',
            '/news/*',
            '/posts/*',
            '/primary-law/*',
            '/secondary-sources/*',
            '/practitioner/*',
            '/learning/*',
            '/documents_and_forms/*',
          ],
          pathname,
        )?.length > 0,
    };
  }, [pathname, search]);

  const [type, setType] = React.useState(defaultSearchType);

  React.useEffect(() => {
    setType(defaultSearchType);
  }, [setType, defaultSearchType]);

  const handleSearch = useSearchQueryNavigation();

  const onQueryChange = React.useCallback(
    debounce(350)(e => {
      setQuery(e.target.value);
    }),
    [setQuery],
  );

  React.useEffect(() => {
    setQuery(defaultQuery);
  }, [setQuery, defaultQuery]);

  const placeholder = useSearchTitleForPathname(type);

  return (
    <StyledLoggedInNav>
      <HeaderMenu as={StyledCompactNavHeaderButton}>
        <>
          <i className="fas fa-bars" /> {current}
        </>
        <StyledHeaderMenuList>
          <HeaderMenuListItem to="/" active={isDailyNewsSubsection} end>
            <img src="/images/nav/daily-news.svg" alt="" />
            DailyNews
          </HeaderMenuListItem>
          <HeaderMenuListItem>
            <span>
              <PrimaryLaw fill={CEB_COLOR('DARK_GREY_BLUE')} />
              Primary Law
            </span>
            <ul>
              <HeaderMenuListItem
                to="/primary-law/cases"
                active={isCaseSection}
              >
                Cases
              </HeaderMenuListItem>
              <HeaderMenuListItem
                to="/primary-law/statutes"
                active={isStatutesSection}
              >
                Statutes
              </HeaderMenuListItem>
              <HeaderMenuListItem
                to="/primary-law/rules"
                active={isRulesSection}
              >
                Rules of Court
              </HeaderMenuListItem>
            </ul>
          </HeaderMenuListItem>
          <HeaderMenuListItem
            to="/secondary-sources"
            active={isSecondarySourcesSection}
          >
            <img src="/images/nav/secondary-sources.svg" alt="" />
            Secondary Sources
          </HeaderMenuListItem>
          {hasPractitionerVisible ? (
            <HeaderMenuListItem
              to="/practitioner"
              active={isPractitionerSection}
            >
              <img src="/images/nav/practitioner.svg" alt="" />
              Practitioner
            </HeaderMenuListItem>
          ) : null}
          {hasFormsAndMatters && (
            <>
              <HeaderMenuListItem
                to="/documents_and_forms"
                active={isDocsAndFormsSection}
              >
                <img src="/images/nav/docs-forms.svg" alt="" />
                Documents &amp; Forms
              </HeaderMenuListItem>{' '}
              <HeaderMenuListItem to="/matters" active={isMattersSection}>
                <img src="/images/nav/client-matters.svg" alt="" />
                Client Matters
              </HeaderMenuListItem>
            </>
          )}
          {!localUser?.ipSession && (
            <HeaderMenuListItem to="/learning">
              <img src="/images/nav/learning.svg" alt="" />
              Learning
            </HeaderMenuListItem>
          )}
          {hasOnlawProducts && (
            <HeaderMenuListItem
              className="onlaw-item"
              to="/onlaw"
              active={isOnLawSection}
              title="OnLaw"
            >
              <OnLawIcon />
            </HeaderMenuListItem>
          )}
          {hasCommunityProduct && (
            <HeaderMenuListItem href="https://community.ceb.com">
              <img src="/images/nav/community.svg" alt="" />
              Community
            </HeaderMenuListItem>
          )}
        </StyledHeaderMenuList>
      </HeaderMenu>
      <div>
        <JsonForm onSubmit={handleSearch}>
          <input type="hidden" name="type" value={type} />
          {showSearch && (
            <HeaderSearchInput
              placeholder={placeholder}
              type={type}
              query={query}
              onQueryChange={onQueryChange}
            />
          )}
        </JsonForm>
      </div>
    </StyledLoggedInNav>
  );
};

interface StyledCompactNavHeaderButtonProps {
  open?: boolean;
}

const StyledCompactNavHeaderButton = styled.div<StyledCompactNavHeaderButtonProps>`
  padding-right: 12px;
  > button {
    background-color: ${({ open }) => (open ? 'white' : '#2660a9')};
    border-radius: 3px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    color: ${({ open }) => (open ? CEB_COLOR('CEB_HEADER_BLUE') : 'white')};
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 14px;
    padding: 10px 17px 9px 12px;
    position: relative;
    white-space: nowrap;
    > i {
      font-size: 20px;
      vertical-align: -2px;
      margin-right: 0.4em;
    }
    ${({ open }) =>
      open &&
      css`
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #d2d2d2;
        border-bottom-color: white;
        &:after {
          border: 1px solid transparent;
          border-top-color: white;
          bottom: -4px;
          content: ' ';
          height: 1px;
          left: 0;
          position: absolute;
          width: calc(100% - 2px);
          z-index: 2;
        }
      `}
  }
`;

const StyledLoggedInNav = styled.div`
  flex: 1 1 auto;
  display: flex;
  color: ${CEB_COLOR('BLACK')};
  > div {
    padding-top: 10px;
    flex: 1 1 auto;
    &:first-child {
      flex: 0 1 auto;
    }
  }
`;
